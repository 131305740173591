<template>
  <a-spin :spinning="loading">
    <!-- Confirmation Modal -->
    <a-modal
      v-if="confirmationModal"
      style="top: 20px;"
      centered
      :title="$t('label.delete.schedule')"
      :closable="false"
      :maskClosable="false"
      v-model:visible="confirmationModal"
    >
      <render-icon icon="question-circle-outlined" :style="{fontSize: '24px', color: 'orange'}"></render-icon>
      <p style="margin-top: 20px;"> {{ confirmationMessage }}</p>
      <span>{{ $t('label.name') + ': ' + recordToDelete.name || undefined }}</span><br/>
      <span v-if="recordToDelete.contacts.length === 1">{{ $t('label.contact') + ': ' + recordToDelete.contacts[0].contact || undefined }}<br/></span>
      <span v-else-if="recordToDelete.contacts.length > 1">
        {{ $t('label.contacts') + ': '}}
        <span v-for="(myContact) in recordToDelete.contacts" :key="myContact.contact">
          <span><br/></span>
          {{ myContact.contact }}
        </span>
      </span>
      <p>{{ $t('label.schedule') + ': ' + $t('label.' + recordToDelete.start_day) + '-' + this.$t('label.' + recordToDelete.end_day) + ', ' + (recordToDelete.start_time.split(':')[0] + ':' + recordToDelete.start_time.split(':')[1]) + '-' + (recordToDelete.end_time.split(':')[0] + ':' + recordToDelete.end_time.split(':')[1]) }}</p>
      <template #footer style="text-align: right;">
        <a-button id="alertConfirmationCancelButton" type="default" @click="confirmationModal=false">Cancel</a-button>
        <a-button id="alertConfirmationOkayButton" type="primary" @click="deleteSchedule(recordToDelete.id)">OK</a-button>
      </template>
    </a-modal>
    <!-- Add Alert Scheduler Modal -->
    <a-modal
      :title="$t('label.add.alert.schedule')"
      v-model:visible="showAddAlertScheduleModal"
      :closable="true"
      destroyOnClose
      @cancel="closeAddScheduleModal()"
      @ok="handleAddScheduleSubmit()"
      okText="OK"
      cancelText="Cancel"
      centered>
      <a-form
        v-ctrl-enter="handleAddScheduleSubmit"
        :ref="formRefAddSchedule"
        :model="formAddSchedule"
        :rules="addScheduleRules"
        @finish="handleAddScheduleSubmit"
        layout="vertical"
      >
        <a-form-item ref="scheduleName" name="scheduleName" :label="$t('label.name')">
        <a-input
            placeholder="Name of the Schedule"
            v-model:value="formAddSchedule.scheduleName"/>
        </a-form-item>
        <a-form-item id="allDayCheck" ref="allDayCheck" name="allDayCheck">
        <a-switch v-model:checked="formAddSchedule.allDayCheck" size="small" /> {{ ' ' + $t('label.24/7')}}
        </a-form-item>
        <div style="display:flex;" v-if="!formAddSchedule.allDayCheck">
            <a-form-item ref="startDay" name="startDay">
            <a-select
                placeholder="Startday"
                v-model:value="formAddSchedule.startDay"
                style="width: 9.1em;">
                <a-select-option :value="weekdayList[0]">
                {{ $t('label.monday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[1]">
                {{ $t('label.tuesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[2]">
                {{ $t('label.wednesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[3]">
                {{ $t('label.thursday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[4]">
                {{ $t('label.friday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[5]">
                {{ $t('label.saturday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[6]">
                {{ $t('label.sunday') }}
                </a-select-option>
            </a-select>
            <span>{{ ' - ' }}</span>
            </a-form-item>
            <a-form-item ref="endDay" name="endDay">
            <a-select
                placeholder="Startday"
                v-model:value="formAddSchedule.endDay"
                style="width: 9.1em; margin-left: 4px;">
                <a-select-option :value="weekdayList[0]">
                {{ $t('label.monday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[1]">
                {{ $t('label.tuesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[2]">
                {{ $t('label.wednesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[3]">
                {{ $t('label.thursday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[4]">
                {{ $t('label.friday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[5]">
                {{ $t('label.saturday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[6]">
                {{ $t('label.sunday') }}
                </a-select-option>
            </a-select>
            </a-form-item>
        </div>
        <div style="display:flex;" v-if="!formAddSchedule.allDayCheck">
        <a-form-item id="startTime" ref="startTime" name="startTime" :label="$t('label.start.time')">
            <a-time-picker
            v-model:value="formAddSchedule.startTime"
            :minute-step="5"
            format="HH:mm"/>
            <span>{{ ' - ' }}</span>
        </a-form-item>
        <a-form-item id="endTime" ref="endTime" name="endTime" :label="$t('label.end.time')" style="margin-left: 4px;">
            <a-time-picker
            v-model:value="formAddSchedule.endTime"
            :minute-step="5"
            format="HH:mm"/>
        </a-form-item>
        </div>
        <a-form-item ref="service" name="service" :label="$t('label.contact')">
          <a-input-group compact>
            <a-select v-model:value="formAddSchedule.service" style="width: 23%">
              <a-select-option value="email">Email</a-select-option>
              <a-select-option value="telegram">Telegram</a-select-option>
            </a-select>
             <a-form-item ref="receive" name="receive" style="width: 77%;">
              <a-input
                :placeholder="(formAddSchedule.service === 'email' ? $t('label.' + formAddSchedule.service) + ' ' + $t('label.address') : $t('label.' + formAddSchedule.service) + ' ' + $t('label.username') )"
                v-model:value="formAddSchedule.receive"/>
            </a-form-item>
          </a-input-group>
          <a-button type="primary" size="small" @click="addContactsAdd()" style="margin-bottom: 3px;">{{ $t('label.add.more') }}</a-button>
          <div>
            <span v-if="contactList.length > 0">{{ $t('label.alert.contacts') }}<br/></span>
            <template v-for="(tag) in contactList"  :key="tag.contact">
              <a-tag :closable="true" :color="tag.type === 'telegram' ? 'blue' : undefined" @close="handleCloseTag(tag.contact)">
                {{ tag.contact }}
              </a-tag>
            </template>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- Edit Alert Scheduler Modal -->
    <a-modal
      :title="$t('label.edit.schedule') + ' ' + editedSchedule"
      v-model:visible="showEditAlertScheduleModal"
      :closable="true"
      destroyOnClose
      @cancel="closeEditScheduleModal()"
      @ok="handleEditScheduleSubmit()"
      okText="OK"
      cancelText="Cancel"
      centered>
      <a-form
        v-ctrl-enter="handleEditScheduleSubmit"
        :ref="formRefEditSchedule"
        :model="formEditSchedule"
        :rules="editScheduleRules"
        @finish="handleEditScheduleSubmit"
        layout="vertical"
      >
        <a-form-item ref="scheduleName" name="scheduleName" :label="$t('label.name')">
        <a-input
            placeholder="Name of the Schedule"
            v-model:value="formEditSchedule.scheduleName"/>
        </a-form-item>
        <a-form-item id="allDayCheck" ref="allDayCheck" name="allDayCheck">
        <a-switch v-model:checked="formEditSchedule.allDayCheck" size="small" /> {{ ' ' + $t('label.24/7')}}
        </a-form-item>
        <div style="display:flex;" v-if="!formEditSchedule.allDayCheck">
            <a-form-item ref="startDay" name="startDay">
            <a-select
                placeholder="Startday"
                v-model:value="formEditSchedule.startDay"
                style="width: 9.1em;">
                <a-select-option :value="weekdayList[0]">
                {{ $t('label.monday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[1]">
                {{ $t('label.tuesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[2]">
                {{ $t('label.wednesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[3]">
                {{ $t('label.thursday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[4]">
                {{ $t('label.friday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[5]">
                {{ $t('label.saturday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[6]">
                {{ $t('label.sunday') }}
                </a-select-option>
            </a-select>
            <span>{{ ' - ' }}</span>
            </a-form-item>
            <a-form-item ref="endDay" name="endDay">
            <a-select
                placeholder="Startday"
                v-model:value="formEditSchedule.endDay"
                style="width: 9.1em; margin-left: 4px;">
                <a-select-option :value="weekdayList[0]">
                {{ $t('label.monday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[1]">
                {{ $t('label.tuesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[2]">
                {{ $t('label.wednesday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[3]">
                {{ $t('label.thursday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[4]">
                {{ $t('label.friday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[5]">
                {{ $t('label.saturday') }}
                </a-select-option>
                <a-select-option :value="weekdayList[6]">
                {{ $t('label.sunday') }}
                </a-select-option>
            </a-select>
            </a-form-item>
        </div>
        <div style="display:flex;" v-if="!formEditSchedule.allDayCheck">
        <a-form-item id="startTime" ref="startTime" name="startTime" :label="$t('label.start.time')">
            <a-time-picker
            v-model:value="formEditSchedule.startTime"
            :minute-step="5"
            format="HH:mm"/>
            <span>{{ ' - ' }}</span>
        </a-form-item>
        <a-form-item id="endTime" ref="endTime" name="endTime" :label="$t('label.end.time')" style="margin-left: 4px;">
            <a-time-picker
            v-model:value="formEditSchedule.endTime"
            :minute-step="5"
            format="HH:mm"/>
        </a-form-item>
        </div>
        <a-form-item ref="service" name="service" :label="$t('label.contact')">
          <a-input-group compact>
            <a-select v-model:value="formEditSchedule.service" style="width: 23%">
              <a-select-option value="email">Email</a-select-option>
              <a-select-option value="telegram">Telegram</a-select-option>
            </a-select>
             <a-form-item ref="receive" name="receive" style="width: 77%;">
              <a-input
                :placeholder="(formEditSchedule.service === 'email' ? $t('label.' + formEditSchedule.service) + ' ' + $t('label.address') : $t('label.' + formEditSchedule.service) + ' ' + $t('label.username') )"
                v-model:value="formEditSchedule.receive"/>
            </a-form-item>
          </a-input-group>
          <a-button type="primary" size="small" @click="addContactsEdit()" style="margin-bottom: 3px;">Add more</a-button>
          <div>
            <span v-if="contactList.length > 0">{{ $t('label.alert.contacts') }}<br/></span>
            <template v-for="(tag) in contactList"  :key="tag.contact">
              <a-tag :closable="true" :color="tag.type === 'telegram' ? 'blue' : undefined" @close="handleCloseTag(tag.contact)">
                {{ tag.contact }}
              </a-tag>
            </template>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- Table Content -->
    <div style="display: flex; align-items: center; margin-bottom: 5px; justify-content: space-between;">
      <div class="title">{{ $t('label.schedule') }}</div>
      <a-button @click="openAddSchedule()" style="margin-left: 10px;"><PlusOutlined/>{{ $t('label.add.schedule') }}</a-button>
    </div>
    <div style="margin-bottom: 20px; max-height: 44.3em; overflow-y: auto">
        <a-table
          :bordered="true"
          size="small"
          :columns="scheduleColumns"
          :dataSource="accountSchedules"
          :rowKey="record => record.id"
          :pagination="false"
        >
        <template #start_day="{ record }">
              <span>{{ $t('label.' + record.start_day) + '-' + $t('label.' + record.end_day) + ', ' + (record.start_time.split(':')[0] + ':' + record.start_time.split(':')[1]) + '-'+ (record.end_time.split(':')[0] + ':' + record.end_time.split(':')[1]) }}</span>
        </template>
        <template #contacts="{ record }">
          <span v-if="record.contacts.length === 1">{{ record.contacts[0].contact }}</span>
          <div v-else>
              <Popover placement="left">
                <template #content>
                  <span v-for="contact in record.contacts" :key="contact.contact">{{ contact.contact }}<br/></span>
                </template>
                <span>{{ record.contacts[0].contact + '... '}} <InfoCircleOutlined/></span>
              </Popover>
          </div>
        </template>
        <template #created="{ record }">
          <div v-if="checkUsedSchedules(record.id)">
            <span v-if="getUsedVMList(record.id).length === 1">{{ getUsedVMList(record.id)[0] }}</span>
            <div v-else>
                <Popover placement="left">
                  <template #content>
                    <span v-for="vmNAme in getUsedVMList(record.id)" :key="vmNAme">{{ vmNAme }}<br/></span>
                  </template>
                  <span>{{ getUsedVMList(record.id)[0] + '... '}} <InfoCircleOutlined/></span>
                </Popover>
            </div>
          </div>
          <span v-else>-</span>
        </template>
        <template #edit="{ record }">
          <span>
            <tooltip-button
              tooltipPlacement="left"
              :tooltip="$t('label.edit.schedule')"
              icon="editOutlined"
              type="default"
              @click="editSchedule(record)" />
          </span>
          <span style="margin-left: 5px;">
            <tooltip-button
              tooltipPlacement="left"
              :tooltip="checkUsedSchedules(record.id) ? $t('label.schedule.disabled') : $t('label.delete.schedule')"
              icon="deleteOutlined"
              type="primary"
              danger
              :disabled="checkUsedSchedules(record.id)"
              @click="openDeleteConfirm(record)" />
          </span>
        </template>
        </a-table>
      </div>
  </a-spin>
</template>

<script>
import TooltipButton from '@/components/widgets/TooltipButton'
import { reactive, ref, toRaw } from 'vue'
import api2 from '@/wpApi/api2'
import moment from 'moment'
import { Popover } from 'ant-design-vue'

export default {
  name: 'VmMonitoring',
  components: {
    TooltipButton,
    Popover
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      weekdayList: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      contactList: [],
      chatID: 'null',
      confirmationModal: false,
      confirmationMessage: '',
      editingScheduleId: '',
      editedSchedule: '',
      showAddAlertScheduleModal: false,
      showEditAlertScheduleModal: false,
      loading: false,
      accountUuid: this.$store.getters.userInfo.accountid,
      accountSchedules: [],
      schedulesinUse: [],
      scheduleColumns: [
        {
          title: this.$t('label.name'),
          dataIndex: 'name',
          width: '15em'
        },
        {
          title: this.$t('label.schedule'),
          dataIndex: 'start_day',
          slots: { customRender: 'start_day' }
        },
        /* {
          title: this.$t('label.created'),
          dataIndex: 'receive'
        }, */
        {
          title: this.$t('label.contact'),
          dataIndex: 'contacts',
          slots: { customRender: 'contacts' }
        },
        {
          title: this.$t('label.schedule.used.by.alert'),
          dataIndex: 'created',
          slots: { customRender: 'created' }
        },
        {
          title: this.$t('label.edit'),
          dataIndex: 'edit',
          slots: { customRender: 'edit' },
          align: 'center',
          width: '7rem'
        }
      ]
    }
  },
  created () {
    this.initForm()
  },
  async mounted () {
    await this.fetchSchedules()
  },
  methods: {
    initForm () {
      this.formRefAddSchedule = ref()
      this.formRefEditSchedule = ref()
      this.formEditSchedule = reactive({
        service: 'email'
      })
      this.formAddSchedule = reactive({
        service: 'email',
        receive: undefined,
        startDay: 'Monday',
        endDay: 'Sunday',
        startTime: moment('00:00', 'HH:mm'),
        endTime: moment('00:00', 'HH:mm'),
        allDayCheck: false
      })
      this.editScheduleRules = reactive({
        receive: [{ required: true, message: this.$t('label.required') }, { validator: this.validateScheduleEmailEdit }],
        scheduleName: [{ required: true, message: this.$t('label.required') }]
      })
      this.addScheduleRules = reactive({
        receive: [{ required: true, message: this.$t('label.required') }, { validator: this.validateScheduleEmail }],
        scheduleName: [{ required: true, message: this.$t('label.required') }]
      })
    },
    async fetchSchedules () {
      await this.getSchedulesInUse()
      await this.getSchedules()
    },
    handleCloseTag (removedTag) {
      const tags = this.contactList.filter(tag => tag.contact !== removedTag)
      this.contactList = tags
    },
    getUsedVMList (scheduleId) {
      var vmList = []
      for (var index in this.schedulesinUse.ScheduleIds) {
        if (this.schedulesinUse.ScheduleIds[index] === scheduleId) {
          // add one vmName only once
          if (vmList.includes(this.schedulesinUse.VmName[index]) === false) {
            vmList.push(this.schedulesinUse.VmName[index])
          }
        }
      }
      return vmList
    },
    addContactsEdit () {
      this.formRefEditSchedule.value.validate().then(async () => {
        const values = toRaw(this.formEditSchedule)
        if (values.receive !== undefined && values.receive !== '') {
          var contactObj = {}
          contactObj.type = values.service
          if (values.service === 'telegram') {
            await api2.getChatIdOfExistingTelegramUser(values.receive.includes('@') ? values.receive.split('@')[1] : values.receive).then(response => {
              this.loading = true
              this.chatID = response.data
            })
            this.loading = false
            contactObj.chatID = this.chatID
            contactObj.contact = values.receive.includes('@') ? values.receive.split('@')[1] : values.receive
          } else {
            contactObj.contact = values.receive
            contactObj.chatID = this.chatID
          }
          this.contactList.push(contactObj)
          this.formEditSchedule.receive = undefined
        }
        if (this.contactList.length > 0) {
          this.editScheduleRules.receive[0].required = false
        }
      })
    },
    addContactsAdd () {
      this.formRefAddSchedule.value.validate().then(async () => {
        const values = toRaw(this.formAddSchedule)
        if (values.receive !== undefined && values.receive !== '') {
          var contactObj = {}
          contactObj.type = values.service
          if (values.service === 'telegram') {
            await api2.getChatIdOfExistingTelegramUser(values.receive.includes('@') ? values.receive.split('@')[1] : values.receive).then(response => {
              this.loading = true
              this.chatID = response.data
            })
            this.loading = false
            contactObj.chatID = this.chatID
            contactObj.contact = values.receive.includes('@') ? values.receive.split('@')[1] : values.receive
          } else {
            contactObj.contact = values.receive
            contactObj.chatID = this.chatID
          }
          this.contactList.push(contactObj)
          this.formAddSchedule.receive = undefined
        }
        if (this.contactList.length > 0) {
          this.addScheduleRules.receive[0].required = false
        }
      })
    },
    async getSchedules () {
      this.loading = true
      api2.getSchedulesByAccount(this.$route.params.id).then(response => {
        this.accountSchedules = response.data
        if (response.data === null || response.data === undefined) {
          this.accountSchedules = []
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error fetching Schedules!',
          description: 'Error fetching Schedules for Alerts. Please try again. If this problem persists please conctact your administrator',
          duration: 0
        })
      })
      this.loading = false
    },
    async getSchedulesInUse () {
      this.loading = true
      api2.getSchedulesInUse(this.accountUuid).then(response => {
        this.schedulesinUse = response.data
        if (response.data === null || response.data === undefined || response.data === '') {
          this.schedulesinUse = []
        }
      }).catch(error => {
        this.loading = false
        console.log(error)
        this.$notification.error({
          message: 'Error fetching Schedules!',
          description: 'Error fetching Schedules in Use. Please try again. If this problem persists please conctact your administrator',
          duration: 0
        })
      })
      this.loading = false
    },
    checkUsedSchedules (scheduleID) {
      if (this.schedulesinUse.ScheduleIds === null || this.schedulesinUse.ScheduleIds === undefined) {
        return false
      } else {
        return this.schedulesinUse.ScheduleIds.includes(scheduleID)
      }
    },
    validateScheduleEmail (rule, value) {
      if ((value === undefined || value === '') && this.contactList.length > 0) {
        return Promise.resolve()
      } else {
        if (this.formAddSchedule.service === 'email') {
          const errorMessage = this.$t('message.validate.email.address')
          var reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if (value !== undefined && reg.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(errorMessage)
        } else if (this.formAddSchedule.service === 'telegram') {
          const errorMessage = this.$t('message.validate.telegram.username') + ' (@username)'
          var reg2 = /^@?(?=\w{5,32}\b)[^0-9]([a-zA-Z0-9_]*)$/
          if (value !== undefined && reg2.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(errorMessage)
        }
      }
    },
    validateScheduleEmailEdit (rule, value) {
      if ((value === undefined || value === '') && this.contactList.length > 0) {
        return Promise.resolve()
      } else {
        if (this.formEditSchedule.service === 'email') {
          const errorMessage = this.$t('message.validate.email.address')
          var reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          if (value !== undefined && reg.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(errorMessage)
        } else if (this.formEditSchedule.service === 'telegram') {
          const errorMessage = this.$t('message.validate.telegram.username') + ' (@username)'
          var reg2 = /^@?(?=\w{5,32}\b)[^0-9]([a-zA-Z0-9_]*)$/
          if (value !== undefined && reg2.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(errorMessage)
        }
      }
    },
    closeEditScheduleModal () {
      this.contactList = []
      this.formRefEditSchedule.value.resetFields()
      this.showEditAlertScheduleModal = false
    },
    openAddSchedule () {
      this.contactList = []
      this.showAddAlertScheduleModal = true
    },
    closeAddScheduleModal () {
      this.showAddAlertScheduleModal = false
      this.formRefAddSchedule.value.resetFields()
      this.contactList = []
      this.addScheduleRules.receive[0].required = true
    },
    handleAddScheduleSubmit () {
      this.formRefAddSchedule.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formAddSchedule)
        var addScheduleParams = new FormData()
        if (values.allDayCheck === true) {
          addScheduleParams.append('startDay', 'Monday')
          addScheduleParams.append('endDay', 'Sunday')
          addScheduleParams.append('startTime', '00:00:00')
          addScheduleParams.append('endTime', '23:59:59')
        } else {
          addScheduleParams.append('startDay', values.startDay)
          addScheduleParams.append('endDay', values.endDay)
          addScheduleParams.append('startTime', values.startTime.format('HH:mm:ss'))
          addScheduleParams.append('endTime', values.endTime.format('HH:mm:ss'))
        }
        addScheduleParams.append('uuid', this.$route.params.id)
        // only one contact is entered -> create the contactList with that one entry
        if ((values.receive !== undefined && values.receive !== '')) {
          var contactObj = {}
          contactObj.type = values.service
          if (values.service === 'telegram') {
            await api2.getChatIdOfExistingTelegramUser(values.receive.includes('@') ? values.receive.split('@')[1] : values.receive).then(response => {
              this.chatID = response.data
            })
            contactObj.chatID = this.chatID
            contactObj.contact = values.receive.includes('@') ? values.receive.split('@')[1] : values.receive
          } else {
            contactObj.chatID = this.chatID
            contactObj.contact = values.receive
          }
          this.contactList.push(contactObj)
        }
        addScheduleParams.append('contact', JSON.stringify(this.contactList))
        addScheduleParams.append('name', values.scheduleName)
        // call createScheduler Api
        api2.createScheduler(addScheduleParams).then(async response => {
          if (response.status === 200) {
            this.getSchedules()
            this.$notification.success({
              message: this.$t('message.success.create.alert.schedule'),
              description: this.$t('message.success.create.alert.schedule')
            })
            // check if Telegram Username(s) are registered yet or not
            var notRegistered = []
            for (const contact of this.contactList) {
              if (contact.type === 'telegram') {
                await api2.checkForTelegramUser(contact.contact).then(response => {
                  if (response.data === false) {
                    notRegistered.push(contact.contact)
                  }
                }).catch(error => {
                  console.log(error)
                })
              }
            }
            if (notRegistered.length > 0) {
              this.$notification.info({
                message: this.$t('label.register.telegram.user'),
                description: this.$t('message.register.telegram.user', [notRegistered]),
                duration: 0
              })
            }
          }
        }).catch(error => {
          console.log(error)
          this.$notifyError(error)
        })
        this.formRefAddSchedule.value.resetFields()
        this.loading = false
        this.showAddAlertScheduleModal = false
      })
    },
    handleEditScheduleSubmit () {
      this.formRefEditSchedule.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.formEditSchedule)
        var editScheduleParams = new FormData()
        if (values.allDayCheck === true) {
          editScheduleParams.append('startDay', 'Monday')
          editScheduleParams.append('endDay', 'Friday')
          editScheduleParams.append('startTime', '00:00:00')
          editScheduleParams.append('endTime', '23:59:59')
        } else {
          editScheduleParams.append('startDay', values.startDay)
          editScheduleParams.append('endDay', values.endDay)
          editScheduleParams.append('startTime', values.startTime.format('HH:mm:ss'))
          editScheduleParams.append('endTime', values.endTime.format('HH:mm:ss'))
        }
        editScheduleParams.append('uuid', this.$route.params.id)
        // only one contact is entered -> create the contactList with that one entry
        if ((values.receive !== undefined && values.receive !== '')) {
          var contactObj = {}
          contactObj.type = values.service
          if (values.service === 'telegram') {
            await api2.getChatIdOfExistingTelegramUser(values.receive.includes('@') ? values.receive.split('@')[1] : values.receive).then(response => {
              this.chatID = response.data
            })
            contactObj.chatID = this.chatID
            contactObj.contact = values.receive.includes('@') ? values.receive.split('@')[1] : values.receive
          } else {
            contactObj.chatID = this.chatID
            contactObj.contact = values.receive
          }
          this.contactList.push(contactObj)
        }
        editScheduleParams.append('contact', JSON.stringify(this.contactList))
        editScheduleParams.append('name', values.scheduleName)
        editScheduleParams.append('id', this.editingScheduleId)
        // call createScheduler Api
        api2.editScheduler(editScheduleParams).then(async response => {
          if (response.status === 200) {
            this.getSchedules()
            this.$notification.success({
              message: this.$t('message.success.edit.alert.schedule'),
              description: this.$t('message.success.edit.alert.schedule')
            })
            // check if Telegram Username(s) are registered yet or not
            var notRegistered = []
            for (const contact of this.contactList) {
              if (contact.type === 'telegram') {
                await api2.checkForTelegramUser(contact.contact).then(response => {
                  if (response.data === false) {
                    notRegistered.push(contact.contact)
                  }
                }).catch(error => {
                  console.log(error)
                })
              }
            }
            if (notRegistered.length > 0) {
              this.$notification.info({
                message: this.$t('label.register.telegram.user'),
                description: this.$t('message.register.telegram.user', [notRegistered]),
                duration: 0
              })
            }
          }
        }).catch(error => {
          console.log(error)
          this.$notifyError(error)
        })
        this.loading = false
        this.formRefEditSchedule.value.resetFields()
        this.showEditAlertScheduleModal = false
      })
    },
    editSchedule (data) {
      this.loading = true
      this.editedSchedule = data.name
      this.contactList = data.contacts
      this.editingScheduleId = data.id
      this.formEditSchedule.contacts = data.contacts || undefined
      this.formEditSchedule.startDay = data.start_day || undefined
      this.formEditSchedule.endDay = data.end_day || undefined
      this.formEditSchedule.startTime = moment(data.start_time, 'HH:mm')
      this.formEditSchedule.endTime = moment(data.end_time, 'HH:mm')
      if (data.end_time === '23:59:59' && data.start_time === '00:00:00') {
        this.formEditSchedule.allDayCheck = true
      } else { this.formEditSchedule.allDayCheck = false }
      this.formEditSchedule.scheduleName = data.name || undefined
      if (this.contactList.length > 0) {
        this.editScheduleRules.receive[0].required = false
      }
      this.loading = false
      this.showEditAlertScheduleModal = true
    },
    openDeleteConfirm (record) {
      this.recordToDelete = record
      this.confirmationModal = true
      this.confirmationMessage = this.$t('message.delete.schedule')
    },
    async deleteSchedule (scheduleId) {
      this.loading = true
      var deleteScheduleParams = new FormData()
      deleteScheduleParams.append('id', scheduleId)
      api2.deleteSchedule(deleteScheduleParams).then(async response => {
        await this.fetchSchedules()
        this.confirmationModal = false
        this.loading = false
      })
      this.confirmationModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-weight: bold;
  }
</style>
